import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { AppSettings } from './app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class AppThemeService {

  appEvent = new EventEmitter<string>();
  document: Document;

  constructor(public appSettings: AppSettings,
    @Inject(DOCUMENT) document: Document) {
    this.document = document;
  }

  handleSetCover(coverClass: string) {
    var htmlElm = this.document.querySelector('html');
    if (htmlElm) {
      for (var x = 0; x < this.document.documentElement.classList.length; x++) {
        var targetClass = this.document.documentElement.classList[x];
        if (targetClass.search('bg-cover-') > -1) {
          htmlElm.classList.remove(targetClass);
        }
      }
      htmlElm.classList.add(coverClass);
    }
  }

  handleSetMode(mode: string) {
    this.document.documentElement.setAttribute('data-bs-theme', mode);
    this.appEvent.emit('theme-reload');
  }

  handleSetTheme(themeClass: string) {
    for (var x = 0; x < this.document.body.classList.length; x++) {
      var targetClass = this.document.body.classList[x];
      if (targetClass.search('theme-') > -1) {
        this.document.body.classList.remove(targetClass);
      }
    }
    this.document.body.classList.add(themeClass);
    this.appEvent.emit('theme-reload');
  }

  initTheme() {
    var elm = this.document.body;
    if (elm) {
      elm.classList.add('app-init');
    }

    if (this.appSettings.appMode) {
      this.handleSetMode(this.appSettings.appMode);
    }
    if (this.appSettings.appTheme) {
      this.handleSetTheme(this.appSettings.appTheme);
    }
    if (this.appSettings.appCover) {
      this.handleSetCover(this.appSettings.appCover);
    }
  }

}
